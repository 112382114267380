import React from 'react';
import CookieConsent from 'react-cookie-consent';
import './cookie-banner.scss';

const CookieBanner = () => (
  <CookieConsent
    buttonText="Accept"
    buttonClasses="cookie-banner-accept-button"
    disableButtonStyles
    disableStyles
    containerClasses="cookie-banner-container"
    contentClasses="cookie-banner-text"
  >
    We use cookies to improve your website user experience and provide you
    with relevant information.
    {' '}
    <a href="/cookie-policy" className="white-link">
      Read more
    </a>
  </CookieConsent>
);

export default CookieBanner;
