import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useGlobalState from 'utils/global-state';

const PublicRoute = ({ path, component }) => {
  const [{ userData }] = useGlobalState();

  if (userData) {
    const redirectFrom = new URLSearchParams(window.location.search).get('redirect_from');
    if (redirectFrom) {
      const redirectURL = `${redirectFrom}?auth_token=${userData.token}`;
      window.location.replace(redirectURL);
    }

    return <Redirect to="/home" />;
  }

  return <Route path={path} component={component} />;
};

export default PublicRoute;
