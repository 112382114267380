import { useHistory } from 'react-router-dom';
import useGlobalState from 'utils/global-state';
import ModalWindow from 'components/molecules/modal-window';
import { ReactComponent as NoRelationsImage } from 'assets/illustrations/no_relations.svg';
import ButtonPrimary from 'components/atoms/button-primary';
import React from 'react';
import '../portrait.scss';

export const NoRelationsModal = ({ isNoRelationsModalOpened, setIsNoRelationsModalOpened }) => {
  const history = useHistory();
  const [{ userData }] = useGlobalState();

  return (
    <ModalWindow
      isOpened={isNoRelationsModalOpened}
      close={() => setIsNoRelationsModalOpened(false)}
      heading="Relations"
    >
      <div className="no-relations-modal-image">
        <NoRelationsImage />
      </div>
      <div className="no-relations-modal-content">
        <p className="text-l no-relations-subtitle">
          To access relations you must be logged in and have your
          HRMNY portrait ready.
        </p>
        {userData ? (
          <p className="no-relations-modal-small-text">
            Seems like you've just completed your onboarding
            process, please wait until your HRMNY portrait is
            ready. You will be notified.
          </p>
        ) : (
          <>
            <ButtonPrimary
              text="Log in to continue"
              onClick={() => history.push('/login')}
            />
            <p className="no-relations-modal-small-text">
              Don't have an account yet?
              {' '}
              <a href="/register">Sign up</a>
            </p>
          </>
        )}
      </div>
    </ModalWindow>
  );
};
