import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/organisms/header';
import Footer from 'components/organisms/footer';
import './main-template.scss';

const MainTemplate = ({
  renderHeaderExtension = false,
  openInviteModal = false,
  openNotAvailableModal = false,
  noFooter = false,
  connections = [],
  onConnectionClick,
  onSpecialConnectionClick,
  headerBackgroundColor,
  children,
}) => {
  return (
    <div className="root-content">
      <Header
        renderExtension={renderHeaderExtension}
        openInviteModal={openInviteModal}
        openNotAvailableModal={openNotAvailableModal}
        connections={connections}
        onConnectionClick={onConnectionClick}
        onSpecialConnectionClick={onSpecialConnectionClick}
        backgroundColor={headerBackgroundColor}
      />
      <main>{children}</main>
      {!noFooter && <Footer />}
    </div>
  );
};

MainTemplate.propTypes = {
  renderHeaderExtension: PropTypes.bool,
  openInviteModal: PropTypes.bool,
  noFooter: PropTypes.bool,
  connections: PropTypes.array,
  onConnectionClick: PropTypes.func,
  headerBackgroundColor: PropTypes.string,
  children: PropTypes.node,
};

export default MainTemplate;
