import React from 'react';
import { useHistory } from 'react-router-dom';
import MainTemplate from 'components/templates/main-template';
import ButtonPrimary from 'components/atoms/button-primary';
import { ReactComponent as PaymentSuccess } from 'assets/icons/payment_good@1x.svg';
import { ReactComponent as PaymentFailure } from 'assets/icons/payment_bad@1x.svg';
import './styles.scss';

const PaymentPage = ({ state }) => {
  const history = useHistory();
  const onClick = () => {
    if (state) {
      history.push('/home');
    } else { history.push('/welcome'); }
  };

  return (
    <MainTemplate headerBackgroundColor="#e8e2de" hideUpgradeSticky>
      <div className="payment">
        { state ? <PaymentSuccess /> : <PaymentFailure />}
        <p className="payment-text">{ state ? 'Thank you for your purchase!' : 'Payment unsuccessful'}</p>
        <ButtonPrimary text="Back to profile" onClick={onClick} />
      </div>
    </MainTemplate>
  );
};

export default PaymentPage;
