import React from 'react';
import PropTypes from 'prop-types';
import './error-info.scss';

const ErrorInfo = ({ errors }) => {
  if (!Object.values(errors).length) return null;

  return Object.values(errors).map((error) => (
    <p className="error-info" key={error}>{error}</p>
  ));
};

ErrorInfo.propTypes = {
  errors: PropTypes.object.isRequired
};

export default ErrorInfo;
