export const getUserInitials = (fullName) => {
  return fullName
    .split(' ')
    .map((word) => word[0])
    .join('');
};

export const getUserQuadraColor = (quadra, darken = false) => {
  const colorByQuadra = {
    I: '#ffddab',
    II: '#f3bdbf',
    III: '#bddfe8',
    IV: '#afe5ca',
  };

  const colorByQuadraDarken = {
    I: '#F4B452',
    II: '#E97376',
    III: '#5C95A5',
    IV: '#49B780',
  };

  return darken ? colorByQuadraDarken[quadra] : colorByQuadra[quadra];
};

export const getParagraphsFromText = (textString) => {
  return textString.split('\n');
};

export const getFormattedName = (name) => {
  return name
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join('');
};

export const portraitPageGuard = ({ timId, planState }) => {
  return timId && planState.includes('_ready_id');
};
