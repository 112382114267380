import React from 'react';
import PropTypes from 'prop-types';
import HeaderUnregistered from 'components/organisms/header-unregistered';
import Footer from 'components/organisms/footer';
import './authentication-template.scss';

const AuthenticationTemplate = ({
  title,
  children,
  isHeaderExtended = true,
  noFooter = false,
  withoutFooterLinks = false,
  isRegistrationPage = false,
  isPortraitSharePage = false,
  mailToTyping = false,
  loginVerification,
  isRussian = false, // TODO: remove this when localization is done
  connections = [],
  renderHeaderExtension = false,
  publicUserData = {},
}) => (
  <div className="root-content">
    <HeaderUnregistered
      isExtended={isHeaderExtended}
      isRegistrationPage={isRegistrationPage}
      connections={connections}
      renderExtension={renderHeaderExtension}
      publicUserData={publicUserData}
    />
    <main className="authentication-page-main">
      <div
        className={`authentication-content-wrapper${
          isPortraitSharePage
            ? ' portrait-share-page-wrapper'
            : ''
        }`}
      >
        {loginVerification && <div className="login-form-verification authentication-form">Success! Email address has been verified. Log in to continue.</div>}
        <div className="page-content">
          {!isPortraitSharePage && (
            <h2 className="page-title">{title}</h2>
          )}
          {children}
        </div>
        {!isPortraitSharePage && (
        <div className="contact-text">
          {isRussian
            ? 'Если у вас возникли какие-либо сложности или просто есть, чем с нами поделиться, пишите на '
            : 'If you encountered any issues or you\'ve got something to share, drop a line to '}
          {mailToTyping ? (
            <a href="mailto:typing@hrmny.ai">
              typing@hrmny.ai
            </a>
          ) : (
            <a href="mailto:hello@hrmny.ai">
              hello@hrmny.ai
            </a>
          )}
        </div>
        )}
      </div>
    </main>
    {!noFooter && <Footer noLinks={withoutFooterLinks} />}
  </div>
);

AuthenticationTemplate.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  isHeaderExtended: PropTypes.bool,
  noFooter: PropTypes.bool,
  withoutFooterLinks: PropTypes.bool,
  isRegistrationPage: PropTypes.bool,
  isPortraitSharePage: PropTypes.bool,
  mailToTyping: PropTypes.bool,
  isRussian: PropTypes.bool,
  connections: PropTypes.array,
  renderHeaderExtension: PropTypes.bool,
  publicUserData: PropTypes.object,
};

export default AuthenticationTemplate;
