import React from 'react';
import AuthenticationTemplate from 'components/templates/authentication-template';

const EnglishInterviewSuccess = () => (
  <AuthenticationTemplate
    isHeaderExtended={false}
    title="You're awesome! Thank you!"
    withoutFooterLinks
  >
    <div className="interview-success-page">
      <p>
        As we are still in the beta mode, it might take some time to
        process your video. To ensure maximum precision, our team of
        experts will be examining the video as well. You can relax and
        expect an email with a link to your HRMNY ID soon.
      </p>
      <p>Sincerely yours, HRMNY team and AI.</p>
    </div>
  </AuthenticationTemplate>
);

export default EnglishInterviewSuccess;
