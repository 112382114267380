import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import UploadPhotosModal from 'components/organisms/invite-friends-modal';
import useGlobalState from 'utils/global-state';
import useWindowSize from 'utils/hooks/window-size';
import { getUserInitials, getUserQuadraColor } from 'utils/helpers';
import { ReactComponent as Logo } from 'assets/icons/logo_monogram.svg';
import { ReactComponent as LogoFull } from 'assets/icons/logo_full.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus_icon.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import './header.scss';
import {NotAvailableModal} from "../../pages/portrait/not-available-modal";

const Header = ({
  renderExtension = false,
  openInviteModal = false,
  openNotAvailableModal = false,
  connections = [],
  onConnectionClick,
  onSpecialConnectionClick,
  backgroundColor = '#ffffff',
}) => {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const connectionsRef = useRef(null);
  const [{ userData }] = useGlobalState();
  const [connectionActive, setConnectionActive] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(openInviteModal);
  const [isNotAvailableModalOpened, setNotAvailableModalOpened] = useState(false);
  const [connectionsSize, setConnectionsSize] = useState({
    clientWidth: null,
    scrollWidth: null,
  });
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(true);

  useEffect(() => {
    if (renderExtension) {
      const handleResize = () => {
        setConnectionsSize({
          clientWidth: connectionsRef.current.clientWidth,
          scrollWidth: connectionsRef.current.scrollWidth,
        });
      };

      setTimeout(() => {
        if (connectionsRef.current) {
          handleResize();
          connectionsRef.current.style.scrollBehavior = 'smooth';
        }
      }, 100);

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [connectionsRef]);

  useEffect(() => {
    if (connectionsSize.clientWidth < connectionsSize.scrollWidth) {
      setIsRightArrowHidden(false);
    } else {
      setIsRightArrowHidden(true);
    }
  }, [connectionsSize]);

  const handleConnectionsScroll = () => {
    if (connectionsRef.current) {
      if (connectionsRef.current.scrollLeft > 0) {
        setIsLeftArrowHidden(false);
      } else {
        setIsLeftArrowHidden(true);
      }

      if (connectionsRef.current.scrollLeft === connectionsSize.scrollWidth - connectionsSize.clientWidth) {
        setIsRightArrowHidden(true);
      } else {
        setIsRightArrowHidden(false);
      }
    }
  };

  const renderConnection = (uuid, name, photo, quadra, index) => (
    <div className="connection" key={index}>
      {photo ? (
        <div
          className={`connection-photo-wrapper${
            index === connectionActive ? ' is-active' : ''
          }`}
          style={{
            border: index === connectionActive
              ? `2px solid ${getUserQuadraColor(quadra)}`
              : 0,
          }}
          onClick={openNotAvailableModal
            ? () => setNotAvailableModalOpened(true)
            : () => {
              onConnectionClick(uuid);
              setConnectionActive(index);
            }
          }
        >
          <div
            className="connection-photo"
            style={{ backgroundImage: `url(${photo})` }}
          />
        </div>
      ) : (
        <span className="connection-photo user-initials">
          {getUserInitials(name === 'Me' ? userData.name : name)}
        </span>
      )}
      <div
        className="connection-name"
        onClick={() => {
          onConnectionClick(uuid);
          setConnectionActive(index);
        }}
      >
        {name}
      </div>
    </div>
  );

  const renderHeaderExtension = () => (
    <div className="header-extension">
      <div className="connections">
        <div
          className="connections-items"
          ref={connectionsRef}
          onScroll={() => handleConnectionsScroll()}
        >
          {renderConnection(userData.uuid, 'Me', userData.avatar)}
          {connections.length > 0
            ? connections.map(
              ({
                uuid, name, avatar, quadra,
              }, index) => renderConnection(
                uuid,
                name,
                avatar,
                quadra,
                index,
              ),
            )
            : isMobile && (
            <div className="no-connections text-xs">
              You have no connections yet. Invite friends
            </div>
            )}
        </div>
        {!isMobile && (
        <div className="connections-arrows">
          <div
            className={`connection-arrow left-arrow${
              isLeftArrowHidden ? ' hidden' : ''
            }`}
            onClick={() => (connectionsRef.current.scrollLeft = connectionsRef.current.scrollLeft - 60)}
          >
            <div className="connection-arrow-icon">
              <Arrow />
            </div>
          </div>
          <div
            className={`connection-arrow right-arrow${
              isRightArrowHidden ? ' hidden' : ''
            }`}
            onClick={() => (connectionsRef.current.scrollLeft = connectionsRef.current.scrollLeft + 60)}
          >
            <div className="gradient" />
            <div className="connection-arrow-icon">
              <Arrow />
            </div>
          </div>
        </div>
        )}
      </div>
      <div
        className="plus-icon-wrapper"
        onClick={() => setIsModalOpened(true)}
      >
        {isMobile && <div className="gradient" />}
        <div className="plus-icon">
          <PlusIcon />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <UploadPhotosModal
        onConnectionClick={onConnectionClick}
        onSpecialConnectionClick={onSpecialConnectionClick}
        isModalOpened={isModalOpened}
        closeModal={() => setIsModalOpened(false)}
      />
      <NotAvailableModal
        isNotAvailableModalOpened={isNotAvailableModalOpened}
        setNotAvailableModalOpened={setNotAvailableModalOpened}
      />
      <header>
        <div
          className="header-main"
          style={{ backgroundColor }}
        >
          <div className="header-left">
            <div
              className="header-logo"
              onClick={() => history.push('/home')}
            >
              {isMobile ? <Logo /> : <LogoFull />}
            </div>
            {renderExtension
              && !isMobile
              && renderHeaderExtension()}
            {renderExtension
              && !isMobile
              && connections.length === 0 && (
                <div className="no-connections text-xs">
                  You have no connections yet. Invite friends
                </div>
              )
            }
          </div>
          <div
            className="header-username"
            onClick={() => history.push('/account')}
          >
            {userData.avatar ? (
              <div
                className="user-avatar"
                style={{
                  backgroundImage: `url(${userData.avatar})`,
                }}
              />
            ) : (
              <span className="user-initials">
                {getUserInitials(userData.name)}
              </span>
            )}
          </div>
        </div>
        {renderExtension && isMobile && renderHeaderExtension()}
      </header>
    </>
  );
};

Header.propTypes = {
  renderExtension: PropTypes.bool,
  openInviteModal: PropTypes.bool,
  connections: PropTypes.array,
  onConnectionClick: PropTypes.func,
  backgroundColor: PropTypes.string,
};

export default Header;
