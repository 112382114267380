import { SHOW_UPGRADE_MODAL } from 'utils/constants';

const AppDataReducer = (state, action) => {
  switch (action.type) {
    case SHOW_UPGRADE_MODAL:
      return {
        ...state,
        appData: {
          ...state.appData,
          showUpgradeModal: action.payload,
        },
      };
    default:
      return state;
  }
};

export default AppDataReducer;
