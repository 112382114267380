import React, {useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useWindowSize from 'utils/hooks/window-size';
import { ReactComponent as Logo } from 'assets/icons/logo_monogram.svg';
import { ReactComponent as LogoFull } from 'assets/icons/logo_full.svg';
import './header-unregistered.scss';
import {getUserInitials} from "../../../utils/helpers";
import {ReactComponent as Arrow} from "../../../assets/icons/arrow.svg";
import {ReactComponent as PlusIcon} from "../../../assets/icons/plus_icon.svg";
import {NoRelationsModal} from "../../pages/portrait/no-relations-modal";

const HeaderUnregistered = ({
  isExtended = true,
  renderExtension = false,
  isRegistrationPage = false,
  connections = [],
  publicUserData = {},
}) => {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const connectionsRef = useRef(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [connectionsSize, setConnectionsSize] = useState({
    clientWidth: null,
    scrollWidth: null,
  });
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(true);

  useEffect(() => {
    if (renderExtension) {
      const handleResize = () => {
        setConnectionsSize({
          clientWidth: connectionsRef.current.clientWidth,
          scrollWidth: connectionsRef.current.scrollWidth,
        });
      };

      setTimeout(() => {
        if (connectionsRef.current) {
          handleResize();
          connectionsRef.current.style.scrollBehavior = 'smooth';
        }
      }, 100);

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [connectionsRef]);

  useEffect(() => {
    if (connectionsSize.clientWidth < connectionsSize.scrollWidth) {
      setIsRightArrowHidden(false);
    } else {
      setIsRightArrowHidden(true);
    }
  }, [connectionsSize]);

  const handleConnectionsScroll = () => {
    if (connectionsRef.current) {
      if (connectionsRef.current.scrollLeft > 0) {
        setIsLeftArrowHidden(false);
      } else {
        setIsLeftArrowHidden(true);
      }

      if (connectionsRef.current.scrollLeft === connectionsSize.scrollWidth - connectionsSize.clientWidth) {
        setIsRightArrowHidden(true);
      } else {
        setIsRightArrowHidden(false);
      }
    }
  };

  const handleModalOpen = () => setIsModalOpened(true);

  const renderConnection = (uuid, name, photo, quadra, index) => (
    <div className="connection" key={index}>
      {photo ? (
        <div
          className="connection-photo-wrapper"
          style={{ border: 0 }}
          onClick={handleModalOpen}
        >
          <div
            className="connection-photo"
            style={{ backgroundImage: `url(${photo})` }}
          />
        </div>
      ) : (
        <span className="connection-photo user-initials">
          {getUserInitials(name === 'Me' && !!publicUserData?.name ? publicUserData?.name : name)}
        </span>
      )}
      <div
        className="connection-name"
        onClick={handleModalOpen}
      >
        {name}
      </div>
    </div>
  );

  const renderHeaderExtension = () => (
    <div className="header-extension">
      <div className="connections">
        <div
          className="connections-items"
          ref={connectionsRef}
          onScroll={() => handleConnectionsScroll()}
        >
          {renderConnection(publicUserData?.uuid, 'Me', publicUserData?.avatar)}
          {connections?.length > 0
            ? connections.map(
              ({
                 uuid, name, avatar, quadra,
               }, index) => renderConnection(
                uuid,
                name,
                avatar,
                quadra,
                index,
              ),
            )
            : isMobile && (
            <div className="no-connections text-xs">
              You have no connections yet. Invite friends
            </div>
          )}
        </div>
        {!isMobile && (
          <div className="connections-arrows">
            <div
              className={`connection-arrow left-arrow${
                isLeftArrowHidden ? ' hidden' : ''
              }`}
              onClick={() => (connectionsRef.current.scrollLeft = connectionsRef.current.scrollLeft - 60)}
            >
              <div className="connection-arrow-icon">
                <Arrow />
              </div>
            </div>
            <div
              className={`connection-arrow right-arrow${
                isRightArrowHidden ? ' hidden' : ''
              }`}
              onClick={() => (connectionsRef.current.scrollLeft = connectionsRef.current.scrollLeft + 60)}
            >
              <div className="gradient" />
              <div className="connection-arrow-icon">
                <Arrow />
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="plus-icon-wrapper"
        onClick={() => setIsModalOpened(true)}
      >
        {isMobile && <div className="gradient" />}
        <div className="plus-icon">
          <PlusIcon />
        </div>
      </div>
    </div>
  );


  return (
    <header>
      <div className="header-main">
        <div className="header-left">
          <div className="header-logo" onClick={() => history.push('/')}>
            {isMobile ? <Logo /> : <LogoFull />}
          </div>
          {renderExtension && !isMobile && renderHeaderExtension()}
          {renderExtension && !isMobile && connections.length === 0 && (
            <div className="no-connections text-xs">
              You have no connections yet. Invite friends
            </div>
          )}
        </div>
        {isExtended && (
        <div className="header-right-side text-s">
          {isRegistrationPage && (
          <span>
            Already registered?
            {' '}
            <a href="/login">Log in</a>
          </span>
          )}
          {}
        </div>
        )}
      </div>
      {renderExtension && isMobile && renderHeaderExtension()}
      <NoRelationsModal isNoRelationsModalOpened={isModalOpened} setIsNoRelationsModalOpened={setIsModalOpened} />
    </header>
  );
};

HeaderUnregistered.propTypes = {
  isExtended: PropTypes.bool,
  renderExtension: PropTypes.bool,
  isRegistrationPage: PropTypes.bool,
  connections: PropTypes.array,
  publicUserData: PropTypes.object,
};

export default HeaderUnregistered;
