import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './text-input.scss';

const TextInput = ({
  name,
  register,
  error,
  label,
  requirementText,
  placeholder,
  value,
  type = 'text',
  inputmode = 'text',
  disabled = false,
  isTextarea = false,
  onChange,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <div
      className={`text-input-wrapper${disabled ? ' disabled' : ''}${
        error ? ' has-error' : ''
      }`}
    >
      <div className="text-label-wrapper">
        <p className="text-input-label text-xs">{label}</p>
        {requirementText && (
        <span className="requirement-text text-xs">
          {requirementText}
        </span>
        )}
      </div>
      <label className="input-container">
        {isTextarea ? (
          <textarea
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            ref={register}
          />
        ) : (
          <input
            type={
              type === 'password'
                ? passwordShown
                  ? 'text'
                  : 'password'
                : type
            }
            inputMode={inputmode}
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            ref={register}
            onChange={onChange}
          />
        )}
        {type === 'password' && (
          <div
            className={`password-icon ${
              passwordShown ? 'icon-on' : 'icon-off'
            }`}
            onClick={() => setPasswordShown(!passwordShown)}
          />
        )}
      </label>
      {error && <div className="error-message text-s">{error.message}</div>}
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  error: PropTypes.object,
  label: PropTypes.string,
  requirementText: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  inputmode: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isTextarea: PropTypes.bool,
};

export default TextInput;
