import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

export const Context = createContext();

const GlobalStateProvider = ({ reducer, initialState = {}, children }) => {
  const value = useReducer(reducer, initialState);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

GlobalStateProvider.propTypes = {
  reducer: PropTypes.func,
  initialState: PropTypes.object,
};

const useGlobalState = () => {
  return useContext(Context);
};

export { GlobalStateProvider, useGlobalState as default };
