import { ReactComponent as GoalDriven } from 'assets/illustrations/pattern-illustrations/76@1x.svg';
import { ReactComponent as GoalOriented } from 'assets/illustrations/pattern-illustrations/77@1x.svg';
import { ReactComponent as GoalObservant } from 'assets/illustrations/pattern-illustrations/78@1x.svg';
import { ReactComponent as GoalDirected } from 'assets/illustrations/pattern-illustrations/79@1x.svg';
import { ReactComponent as StressActivated } from 'assets/illustrations/pattern-illustrations/80@1x.svg';
import { ReactComponent as StressResistant } from 'assets/illustrations/pattern-illustrations/81@1x.svg';
import { ReactComponent as StressPhobic } from 'assets/illustrations/pattern-illustrations/82@1x.svg';
import { ReactComponent as StressDynamic } from 'assets/illustrations/pattern-illustrations/83@1x.svg';
import { ReactComponent as Status } from 'assets/illustrations/pattern-illustrations/32@1x.svg';
import { ReactComponent as Resources } from 'assets/illustrations/pattern-illustrations/33@1x.svg';
import { ReactComponent as Interest } from 'assets/illustrations/pattern-illustrations/34@1x.svg';
import { ReactComponent as Fame } from 'assets/illustrations/pattern-illustrations/35@1x.svg';

// Validation
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REQUIRED_MESSAGE = 'This field is required';
export const INVALID_EMAIL_MESSAGE = 'Please, enter a valid email address';

// Tiles arrangement
export const TILE_ONE_COLUMN = '1column';
export const TILE_TWO_COLUMNS = '2columns';
export const TILE_MATCH = '1column-match';
export const TILE_MISMATCH = '1column-mismatch';
export const TILE_LARGE = 'tile-large';

// Content block
export const BLOCK_NARROW = 'content-block-narrow';

// Plans
export const PLAN_BASIC = 'basic';

// Plan states
export const PLAN_NO_PLAN = 'draft';
export const PLAN_GOT_INTERVIEW = 'videoask_got_interview';
export const PLAN_GOT_PHOTOS = 'got_photo';
export const PLAN_MEDIA_ACCEPTED = 'media_ready';
export const PLAN_READY_FOR_MEETING = 'ready_to_book';
export const PLAN_MEETING_BOOKED = 'cal_booked';
export const PLAN_BASIC_READY = 'basic_ready_id';
export const PLAN_ADVANCED_READY = 'advanced_ready_id';
export const PLAN_PHOTO_RECALL = 'media_recall_bs';
export const PLAN_MEETING_READY = 'meeting_ready';

// Plan payment
export const PAYMENT_BASIC = 'basic_paid';

// Landing sections
export const LANDING_SECTIONS = {
  WELCOME: 'landing-welcome',
  SUCCESS: 'landing-success',
  RELATIONS: 'landing-relations',
  PRIVACY: 'landing-privacy',
  REVIEWS: 'landing-reviews',
  PRICES: 'landing-prices',
};

// Videoask front types
export const VIDEOASK_HI_TYPING = 'hityping_rus';
export const VIDEOASK_TYPING = 'typing_rus';
export const VIDEOASK_MKT = 'mkt_rus';

// Reducers actions
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SHOW_UPGRADE_MODAL = 'SHOW_UPGRADE_MODAL';

export const DESKTOP_BREAKPOINT = 769;
export const LANDING_DESKTOP_BREAKPOINT = 800;

// Pattern Mockup
export const PATTERN_CONTENT = {
  'Ways to work with goals': [
    {
      image: <GoalDriven />,
      title: 'Goal-driven',
      description: 'You have the most effective mind for achieving goals. You get inspired by the goals of other people (family, friends, colleagues, society) and reach them in a straightforward and vigorous way. No obstacles can deviate you on your path to success. For you, the only option to eliminate a goal is to accomplish it, that\'s a positive feature. However, you tend to have issues when setting, prioritizing, or canceling your goals on your own. Don’t be shy to get inspired by people around you, and ask for help to manage your goals and keep them up-to-date.',
    },
    {
      image: <GoalOriented />,
      title: 'Goal-oriented',
      description:
        "You can easily identify, set, and prioritise goals independently. When facing an obstacle, you choose to go around it, instead of overcoming it. You have a tendency to reach goals by putting in as little effort as possible. Unfortunately, you're not able to cancel or change your own goals. Instead of cancelling, you set them to a lower priority but still keep pushing yourself to reach them, which leads to having a stockpile of irrelevant goals. To avoid this, make sure to turn to your peers for help with eliminating or changing your goals.",
    },
    {
      image: <GoalObservant />,
      title: 'Goal-observant',
      description: 'You get inspired by goals from people around you and act with the maximum precision and effort to accomplish them. You fulfill your goals, while helping other people to achieve theirs as well. Unfortunately you can\'t flexibly set, change, prioritize, or cancel goals. So ask your peers for help and you will find solutions in conversations. You can work hard for a long time and only then analyze if you got closer to your goal. Acting without a goal is also an option for you, but to be productive, you should see the general direction clearly.',
    },
    {
      image: <GoalDirected />,
      title: 'Goal-directed',
      description: 'You are extremely creative in setting goals and changing them with ease. The ability to prioritize, change, or cancel goals gives you great strategic freedom. Unfortunately, you aren\'t equipped with the ability to overcome or avoid obstacles on your way. Instead of dealing with difficulties, you change the goal completely. You will only be able to achieve goals with a clear path ahead of you. Consult with your peers and ask them for help to manage the obstacles in your way.',
    },
  ],

  'Stress management methods': [
    {
      image: <StressActivated />,
      title: 'Stress-activated',
      description: 'Stress powers and drives you. The more stress you have in your life, the more active and accurate you become. However, that only works until you reach a breaking point, when you need to take time off and recharge. Keep in mind that your peak productivity always comes with a generous pinch of stress.',
    },
    {
      image: <StressResistant />,
      title: 'Stress-resistant',
      description: 'During stressful situations, you tend to reduce the number of activities but significantly increase their duration. You try to resist stress by investing more time in each activity. When noticing this kind of behavior, make sure it doesn\'t last very long.',
    },
    {
      image: <StressPhobic />,
      title: 'Stress-phobic',
      description: 'The more stress is in your life, the more passive you become. To stay productive, try to avoid stress as much as possible. Don\'t blame yourself for a significant drop in activity when encountering stressful situations, it\'s your natural response to operating under pressure.',
    },
    {
      image: <StressDynamic />,
      title: 'Stress-dynamic',
      description: 'You react to stress by becoming more active, switching between tasks, and trying to accomplish many things at once, but that makes your focus on each individual task drop significantly. Doing many things at once within a short activity span is your natural response to stress.',
    },
  ],

  Motivation: [
    {
      image: <Status />,
      title: 'Status',
      description: 'Your best motivator is your job position, role, or an important place in a team structure. Good office and tools are also an essential part for you to have a productive working environment. Access to an “inner circle” of decision-makers and people you respect is a great motivation booster.',
    },
    {
      image: <Resources />,
      title: 'Resources',
      description: 'The best motivation for you is adequate and clear monetary compensation for your efforts in the form of salary, bonuses, or similar. It should be paid as a one-time payment rather than in tranches. Having control over your resources gives your motivation an additional boost.',
    },
    {
      image: <Interest />,
      title: 'Interest',
      description: 'You feel motivated when faced with unique challenges or problems and you\'re being trusted to find a solution. Freedom to operate is a necessary condition for you. Having your opinion and advice treated as valuable gives you an additional boost of motivation.',
    },
    {
      image: <Fame />,
      title: 'Fame',
      description: 'You\'re motivated by others paying attention to your personality, by receiving praise for your knowledge, actions, and achievements. Being part of an "invitation-only" club and interacting with key persons in your industry boosts your motivation to a maximum.',
    },
  ],
};
