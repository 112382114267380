import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg';
import './modal-window.scss';

const ModalWindow = ({
  isOpened, close, heading, children,
}) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      close();
    }
  };

  {}

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    isOpened && (
    <div className="modal-window-background">
      <div className="modal-window" ref={modalRef}>
        <div className="modal-heading-row">
          <h3 className="modal-heading">{heading}</h3>
          <CloseIcon
            onClick={() => close()}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
    )
  );
};

ModalWindow.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  heading: PropTypes.string,
  children: PropTypes.node,
};

export default ModalWindow;
