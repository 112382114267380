import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useGlobalState from 'utils/global-state';

const PrivateRoute = ({
  path, component, redirect = null, routeGuard = null,
}) => {
  const [{ userData }] = useGlobalState();

  if (!userData || (routeGuard && !routeGuard(userData))) {
    return <Redirect to={redirect ?? '/'} />;
  }

  return <Route path={path} component={component} />;
};

export default PrivateRoute;
