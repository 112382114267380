import React from 'react';
import PropTypes from 'prop-types';
import './button-primary.scss';

const ButtonPrimary = ({
  text,
  onClick,
  disabled = false,
  isSmall = false,
  buttonClass,
}) => (
  <button
    className={`button-primary${
      isSmall ? ' button-small' : ''
    }${
      buttonClass ? ` ${buttonClass}` : ''
    }`}
    onClick={onClick}
    disabled={disabled}
  >
    <div className={`button-text ${isSmall ? 'text-l' : 'text-xl'}`}>
      {text}
    </div>
  </button>
);

ButtonPrimary.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  buttonClass: PropTypes.string,
};

export default ButtonPrimary;
