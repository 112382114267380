import { useState } from 'react';
import axios from 'axios';
import useGlobalState from 'utils/global-state';

const useHttpClient = () => {
  const [{ userData }] = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (userData) headers.Authorization = `Bearer ${userData.token}`;

  const sendRequest = async (
    url,
    method = 'GET',
    data = null,
    customHeaders = null,
  ) => {
    setIsLoading(true);

    if (customHeaders) {
      Object.entries(customHeaders).map(
        (customHeader) => (headers[customHeader[0]] = customHeader[1]),
      );
    }

    try {
      const response = await axios(url, { method, data, headers });
      setIsLoading(false);

      return response.data;
    } catch (error) {
      setIsLoading(false);
      setHasError(true);

      throw error;
    }
  };

  return { sendRequest, isLoading, hasError };
};

export default useHttpClient;
