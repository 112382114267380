import React from 'react';
import GoodExamples from '../../../../../../assets/images/upload-good-example.png';
import BadExamples from '../../../../../../assets/images/upload-bad-example.png';
import './photos-guidelines.scss';

const PhotosGuidelines = () => (
  <div className="photos-guidelines">
    <ul className="guidelines-list">
      <li>
          In order for our artificial intelligence to ensure maximum accuracy, please follow the following rules when uploading photos:
          <ul className="guidelines-list">
              <li>Please upload a photo in JPG format</li>
              <li>Make sure your face is fully visible on each photo</li>
              <li>Don’t upload dark or group photos</li>
          </ul>
      </li>
    </ul>
    <p className="example-subtitle">Here are some good and bad photo examples:</p>
    <div className="examples-container">
      <p className="example-subtitle">Do</p>
      <img src={GoodExamples} alt="Good examples" className="example-photos" />
      <p>Full face, bright, straight, no other faces, close-up</p>
      <p className="example-subtitle">Don't</p>
      <img src={BadExamples} alt="Bad examples" className="example-photos" />
      <p>Dark, blurry, other faces, glasses, too distant, poor lighting</p>
    </div>
  </div>
);

export default PhotosGuidelines;
