import { useState, useEffect } from 'react';
import {
  DESKTOP_BREAKPOINT,
  LANDING_DESKTOP_BREAKPOINT,
} from 'utils/constants';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobile: undefined,
    isLandingMobile: undefined,
  });

  // TODO: check for better solution
  const getMinWidth = () => {
    return Math.min(window.innerWidth, window.screen.availWidth);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: getMinWidth(),
        height: window.innerHeight,
        isMobile: getMinWidth() < DESKTOP_BREAKPOINT,
        isLandingMobile: getMinWidth() < LANDING_DESKTOP_BREAKPOINT,
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
