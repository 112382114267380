import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {Redirect, useHistory} from 'react-router-dom';
import ErrorPage from 'components/pages/error';
import useGlobalState from 'utils/global-state';
import useHttpClient from 'utils/hooks/http-request';
import { useParams } from "react-router-dom";

import {LOGIN, LOGOUT} from 'utils/constants';
import api from 'utils/api';

const SocialLogin = () => {
  const history = useHistory();
  const [state, dispatch] = useGlobalState();
  const { sendRequest, hasError } = useHttpClient();
  const { token } = useParams();
  const [cookies] = useCookies();
  const authCookies = process.env.REACT_APP_ENV === 'STAGE'
    ? cookies.authPortraitstage
    : cookies.authPortraitproduction;

  useEffect(() => {
    dispatch({ type: LOGOUT })

    let authToken = authCookies;

    if (token)  {
      authToken = token;
    }
    if (authToken) {
      getPortraitInfo(authToken);
    } else {
      history.push('/login');
    }
  }, []);

  const getPortraitInfo = async (token) => {
    const getInfoUri = api.getPortraitInfo();

    try {
      const response = await sendRequest(getInfoUri, 'GET', null, {
        Authorization: `Bearer ${token}`,
      });

      if (response.success) {
        dispatch({
          type: LOGIN,
          payload: {
            token: token,
            uuid: response.success.uuid,
            name: response.success.userName,
            email: response.success.userEmail,
          },
        });
        history.push('/home');
      }
    } catch (error) {
      /** Empty */
    }
  };

  if (hasError) {
    return <ErrorPage />;
  }

  return null;
};

export default SocialLogin;
