import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import ScrollToTop from 'utils/hooks/scroll-to-top-router';
import PrivateRoute from 'components/atoms/routes/private-route';
import PublicRoute from 'components/atoms/routes/public-route';
import CookieBanner from 'components/molecules/cookie-banner';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { GlobalStateProvider } from 'utils/global-state';
import { initialState, combineReducers } from 'utils/global-state/reducers';
import UserDataReducer from 'utils/global-state/reducers/user-data-reducer';
import AppDataReducer from 'utils/global-state/reducers/app-data-reducer';
import { portraitPageGuard } from 'utils/helpers';
import { InterviewSuccess } from 'components/pages/interview-success';
import PaymentPage from 'components/pages/payment-notification';
import SocialLogin from 'components/pages/social-login';

const CareersPage = lazy(() => import('components/pages/careers'));
const VacancyPage = lazy(() => import('components/pages/careers-vacancy'));
const CareersInterviewPage = lazy(() => import('components/pages/careers-interview'));
const CareersInterviewSuccessPage = lazy(() => import('components/pages/careers-interview-success'));
const AboutUsPage = lazy(() => import('components/pages/about-us'));
const LandingPage = lazy(() => import('components/pages/landing'));
const ErrorPage = lazy(() => import('components/pages/error'));
const PrivacyPolicyPage = lazy(() => import('components/pages/privacy-policy'));
const CookiePolicyPage = lazy(() => import('components/pages/cookie-policy'));
// const SocialLogin = lazy(() => import('components/pages/social-login'));
const RegistrationPage = lazy(() => import('components/pages/registration'));
const LoginPage = lazy(() => import('components/pages/login'));
const ForgotPasswordPage = lazy(() => import('components/pages/forgot-password'));
const ResetPasswordPage = lazy(() => import('components/pages/reset-password'));
const Home = lazy(() => import('components/pages/home'));
const WelcomePage = lazy(() => import('components/pages/welcome'));
const InterviewPage = lazy(() => import('components/pages/interview'));
const CastingInterviewPage = lazy(() => import('components/pages/casting'));
const AccountPage = lazy(() => import('components/pages/account'));
const PortraitPage = lazy(() => import('components/pages/portrait'));
const TermsConditionsPage = lazy(() => import('components/pages/terms-conditions'));
const UnsubscribePage = lazy(() => import('components/pages/unsubscribe'));
const PortraitCardsPage = lazy(() => import('components/pages/portrait-cards'));

const appReducers = combineReducers({
  userData: UserDataReducer,
  appData: AppDataReducer,
});

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://4df91a285e8f4364b68b409b7cf6348f@o1118765.ingest.sentry.io/6722428',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const App = () => {
  const routes = (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/careers/:id" component={VacancyPage} />
      <Route path="/careers" component={CareersPage} />
      <Route path="/careers-interview" component={CareersInterviewPage} />
      <Route
        path="/careers-interview-success"
        component={CareersInterviewSuccessPage}
      />
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/payment-success" render={() => <PaymentPage state />} />
      <Route path="/payment-failure" render={() => <PaymentPage state={false} />} />
      <Route path="/error-page" component={ErrorPage} />
      <Route path="/unsubscribe-success" component={UnsubscribePage} />

      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route path="/cookie-policy" component={CookiePolicyPage} />
      <Route path="/terms-conditions" component={TermsConditionsPage} />
      <Route path="/portrait/:uuid" component={PortraitPage} />
      <Route path="/interview" component={InterviewPage} />
      <Route path="/interview-success" component={InterviewSuccess} />
      <PublicRoute path="/casting" component={CastingInterviewPage} />
      <Route path={"/sociallogin/:token?"} component={SocialLogin} />
      <PublicRoute path="/register" component={RegistrationPage} />
      <PublicRoute path="/login" component={LoginPage} />
      <PublicRoute
        path="/forgot-password"
        component={ForgotPasswordPage}
      />
      <PublicRoute path="/reset-password" component={ResetPasswordPage} />
      <PrivateRoute path="/account" component={AccountPage} />
      <PrivateRoute path="/welcome" component={WelcomePage} />
      <PrivateRoute path="/portrait-cards" component={PortraitCardsPage} />
      <PrivateRoute
        path="/portrait"
        component={PortraitPage}
        redirect="/login"
        routeGuard={portraitPageGuard}
      />
      <PrivateRoute exact path="/home" component={Home} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );

  return (
    <GlobalStateProvider reducer={appReducers} initialState={initialState}>
      <Router>
        <ScrollToTop />
        <Suspense fallback={<div />}>{routes}</Suspense>
        <CookieBanner />
      </Router>
    </GlobalStateProvider>
  );
};

export default App;
