import { LOGIN, LOGOUT, UPDATE_USER_INFO } from 'utils/constants';

const UserDataReducer = (state, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem('userData', JSON.stringify(action.payload));

      return {
        ...state,
        userData: action.payload,
      };
    case LOGOUT:
      localStorage.clear();

      return {
        ...state,
        userData: null,
      };
    case UPDATE_USER_INFO:
      const userDataObject = { ...state.userData, ...action.payload };
      localStorage.setItem('userData', JSON.stringify(userDataObject));

      return {
        ...state,
        userData: userDataObject,
      };
    default:
      return state;
  }
};

export default UserDataReducer;
