import React from 'react';
import { useLocation } from 'react-router-dom';
import MktInterviewSuccess from 'components/organisms/interview-success/mkt';
import HiTypingInterviewSuccess from 'components/organisms/interview-success/hi-typing';
import TypingInterviewSuccess from 'components/organisms/interview-success/typing';
import EnglishInterviewSuccess from 'components/organisms/interview-success/eng';
import {
  VIDEOASK_HI_TYPING,
  VIDEOASK_TYPING,
  VIDEOASK_MKT,
} from 'utils/constants';
import './interview-success.scss';

const PublicInterviewSuccessPage = () => {
  const urlParams = new URLSearchParams(useLocation().search);
  const sourceParam = urlParams.get('source');

  if (sourceParam === VIDEOASK_MKT) {
    return <MktInterviewSuccess />;
  } if (sourceParam === VIDEOASK_HI_TYPING) {
    return <HiTypingInterviewSuccess />;
  } if (sourceParam === VIDEOASK_TYPING) {
    return <TypingInterviewSuccess />;
  }

  return <EnglishInterviewSuccess />;
};

export default PublicInterviewSuccessPage;
