import ModalWindow from "../../../molecules/modal-window";
import {ReactComponent as NoRelationsImage} from "../../../../assets/illustrations/no_relations.svg";
import ButtonPrimary from "../../../atoms/button-primary";
import React from "react";

export const NotAvailableModal = ({ isNotAvailableModalOpened, setNotAvailableModalOpened }) => {
  return (
    <ModalWindow
      isOpened={isNotAvailableModalOpened}
      close={() => setNotAvailableModalOpened(false)}
      heading="Relations"
    >
      <div className="no-relations-modal-image">
        <NoRelationsImage />
      </div>
      <div className="no-relations-modal-content">
        <p className="text-l no-relations-subtitle">
          This section is not available to you, because you are not part of this company.
        </p>
        <ButtonPrimary
          isSmall
          text="Ok"
          onClick={() => setNotAvailableModalOpened(false)}
        />
      </div>
    </ModalWindow>
  );
};
