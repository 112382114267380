import React from 'react';
import AuthenticationTemplate from 'components/templates/authentication-template';
import EvgeniyCourse from 'assets/images/evgeniy-course.png';

const TypingInterviewSuccess = () => (
  <AuthenticationTemplate
    isHeaderExtended={false}
    title="Вы были великолепны! Спасибо!"
    isRussian
    withoutFooterLinks
  >
    <div className="interview-success-page">
      <p>
        Мы дарим Вам исключительную возможность пройти онлайн-интервью с
        нашими экспертами, задать им вопросы и получить практические
        знания. Запишитесь на персональное типирование по почте
        {' '}
        <a href="mailto:typing@hrmny.ai">typing@hrmny.ai</a>
        {' '}
        уже
        сегодня.
      </p>
      <p>
        Обработка Вашего видео может занять некоторое время. Наши
        эксперты свяжутся с Вами и пригласят на видео-интервью для
        определения поведенческих паттернов. Обычно процесс занимает 10
        рабочих дней.
      </p>
      <p>
        Как только результат будет готов, Вы получите от нас письмо на
        указанную Вами почту. Персональный психологический портрет будет
        предоставлен всем, кто прошел оба этапа - запись видео-ответов и
        онлайн-интервью с экспертами.
      </p>
      <h3>Специальный бонус для Вас</h3>
      <p>
        Неограниченный доступ к мастер-классу
        {' '}
        <a href="https://www.youtube.com/watch?v=FzOA0VdVz2w">
          «Мотивация, персональный подход к себе и окружающим»
        </a>
        .
      </p>
      <a href="https://www.youtube.com/watch?v=FzOA0VdVz2w">
        <img src={EvgeniyCourse} alt="Evgeniy course" />
      </a>
    </div>
  </AuthenticationTemplate>
);

export default TypingInterviewSuccess;
