import React, { useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { ReactComponent as CameraIcon } from '../../../../../../assets/icons/camera_icon.svg';
import { ReactComponent as BinIcon } from '../../../../../../assets/icons/bin_icon.svg';
import ButtonPrimary from '../../../../../atoms/button-primary';
import './dropzone.scss';

const MAX_FILE_SIZE = 4096052;
const ERRORS_LIST = {
  bigSize: 'The maximum size of photo should not exceed 4 MB',
  maxFiles: 'You should upload only one photo',
};

const PlanDropzone = ({
  errors,
  index,
  uploadedFiles,
  imageCount,
  setImageCount,
  setUploadedFiles,
  setErrors,
}) => {
  const [id, setId] = useState(0);
  const dropzoneRef = useRef(null);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      removeImage();
    }
  }, [errors]);

  const removeImage = () => {
    setUploadedFiles([]);
    setImageCount(0);
  };

  const onDrop = (acceptedFiles) => {
    setErrors({});

    const bigSizeFile = acceptedFiles.find((file) => file.size > MAX_FILE_SIZE);

    if (bigSizeFile) {
      return setErrors({
        [ERRORS_LIST.bigSize]: [ERRORS_LIST.bigSize],
      });
    }

    if (acceptedFiles.length > 1) {
      return setErrors({
        [ERRORS_LIST.maxFiles]: [ERRORS_LIST.maxFiles],
      });
    }

    setUploadedFiles([
      ...uploadedFiles,
      ...acceptedFiles.map((file, index) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: id + index + 1,
        })
      ),
    ]);
    setId(id + acceptedFiles.length);
    setImageCount(imageCount + acceptedFiles.length);
  };

  const onClickRemove = (e) => {
    e.stopPropagation();
    removeImage();
  };

  const isDisabled = imageCount > 0;

  return (
    <div>
      <Dropzone
        ref={dropzoneRef}
        accept="image/jpeg, image/jpg"
        disabled={isDisabled}
        onDrop={onDrop}
        noClick
        noKeyboard
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone-container" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="dropzone-icon">
              <CameraIcon />
            </div>
            {imageCount > index && (
              <>
                <div
                  className="preview-image"
                  style={{
                    backgroundImage: `url(${uploadedFiles[index].preview})`,
                  }}
                />
                <div className="preview-bin" onClick={onClickRemove}>
                  <BinIcon />
                </div>
              </>
            )}
          </div>
        )}
      </Dropzone>

      <ButtonPrimary
        onClick={() => dropzoneRef.current.open()}
        text={'Upload photo'}
        isSmall
      />
    </div>
  );
};

PlanDropzone.propTypes = {
  index: PropTypes.number.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  imageCount: PropTypes.number.isRequired,
  setImageCount: PropTypes.func.isRequired,
  setUploadedFiles: PropTypes.func.isRequired,
};

export default PlanDropzone;
