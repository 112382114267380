import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from 'assets/icons/logo_full.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/footer_facebook_icon.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/footer_linkedin_icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/footer_instagram_icon.svg';
import './footer.scss';

const Footer = ({ isLandingPage = false, noLinks = false }) => (
  <footer className={isLandingPage ? 'footer-landing' : ''}>
    <div className="logo-wrapper">
      <Logo />
    </div>
  </footer>
);

Footer.propTypes = {
  isLandingPage: PropTypes.bool,
  noLinks: PropTypes.bool,
};

export default Footer;
