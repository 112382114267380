import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useGlobalState from '../../../../../utils/global-state';
import { PLAN_PHOTO_RECALL } from '../../../../../utils/constants';
import { ReactComponent as InformationIcon } from '../../../../../assets/icons/information_icon.svg';
import ModalWindow from '../../../../molecules/modal-window';
import PhotosGuidelines from './photos-guidelines';
import ButtonPrimary from '../../../../atoms/button-primary';
import PlanDropzone from './dropzone';
import './plan-content.scss';
import ErrorInfo from '../../../../atoms/error-info';

const RECOGNIZE_ERROR_TEXT = `AI did not recognize the face in one or more photos. Upload new 
photos that meet the quality requirements.`;

const PlanContent = ({
  submitImages,
  isLoading,
  errors,
  setErrors,
  needTitle = true,
  fullName = '',
}) => {
  const [{ userData }] = useGlobalState();
  const [imageCount, setImageCount] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const closeModal = () => setIsModalOpened(false);
  const openModal = () => setIsModalOpened(true);
  const onClick = () => submitImages(uploadedFiles);
  const isDisabled = imageCount < 1 || isLoading;
  const isDisabledWithFullName = isDisabled || fullName === '';

  const isImagesNotAccepted = userData.planState === PLAN_PHOTO_RECALL && userData.stateMeta;

  const imageUploadBlocks = () => {
    const content = [];
    const uploadBlocksCount = imageCount >= 1 ? imageCount : 1;

    for (let i = 0; i < uploadBlocksCount; i++) {
      content.push(
        <PlanDropzone
          errors={errors}
          key={i}
          index={i}
          uploadedFiles={uploadedFiles}
          imageCount={imageCount}
          setImageCount={setImageCount}
          setUploadedFiles={setUploadedFiles}
          setErrors={setErrors}
        />,
      );
    }

    return content;
  };

  return (
    <div className="plan-content">
      {isImagesNotAccepted && (
        <div className="media-not-accepted">
          <div className="media-not-accepted__content">
            <InformationIcon />
            <div className="media-not-accepted__text">
              {userData.stateMeta.length > 1
                ? userData.stateMeta
                : RECOGNIZE_ERROR_TEXT}
            </div>
          </div>
          <div className="media-not-accepted__divider" />
        </div>
      )}
      <ModalWindow
        isOpened={isModalOpened}
        close={closeModal}
        heading="Photos uploading guidelines"
      >
        <PhotosGuidelines />
      </ModalWindow>
        {needTitle && <h3 className="action-title">Upload your photos</h3>}
        <p className="link text-l examples-button" onClick={openModal}>See examples &amp; requirements</p>
      <ErrorInfo errors={errors} />
      <div className="image-upload-container">
        {imageUploadBlocks()}
      </div>
      <p className="precision-text text-s image-upload-precision">
        Click to button or simply drag-n-drop your photo. The maximum size of photo should not exceed 4
        MB.
      </p>
      <ButtonPrimary
        onClick={onClick}
        disabled={isDisabled}
        text={isLoading ? 'Sending...' : 'Recognize'}
      />
    </div>
  );
};

PlanContent.propTypes = {
  submitImages: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default PlanContent;
