import React from 'react';
import AuthenticationTemplate from 'components/templates/authentication-template';
import EvgeniyCourse from 'assets/images/evgeniy-course.png';

const HiTypingInterviewSuccess = () => (
  <AuthenticationTemplate
    isHeaderExtended={false}
    title="Вы были великолепны! Спасибо!"
    isRussian
    mailToTyping
    withoutFooterLinks
  >
    <div className="interview-success-page">
      <p>
        Поскольку наш искусственный интеллект еще учится распознавать
        русскую речь, обработка вашего видео может занять некоторое
        время. Как только результат будет готов, вы получите от нас
        письмо на указанную вами почту. Обычно процесс занимает 5
        рабочих дней.
      </p>
      <p>
        Cпециальный бонус для вас – доступ к мастер-классу
        {' '}
        <a href="https://www.youtube.com/watch?v=FzOA0VdVz2w">
          «Мотивация, персональный подход к себе и окружающим»
        </a>
        .
      </p>
      <a href="https://www.youtube.com/watch?v=FzOA0VdVz2w">
        <img src={EvgeniyCourse} alt="Evgeniy course" />
      </a>
    </div>
  </AuthenticationTemplate>
);

export default HiTypingInterviewSuccess;
