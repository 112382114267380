import React from 'react';
import MainTemplate from 'components/templates/main-template';
import AuthenticationTemplate from 'components/templates/authentication-template';
import useGlobalState from 'utils/global-state';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';

const ErrorPage = () => {
  const [{ userData }] = useGlobalState();

  const pageContent = () => (
    <div className="icon-text-page">
      <ErrorIcon />
      <p className="icon-text-page-text text-l">
        Sorry, but something went wrong. We are investigating the error.
        Please, come back later.
      </p>
      <p className="link" onClick={() => window.location.reload()}>
        Back
      </p>
    </div>
  );

  if (userData) {
    return <MainTemplate>{pageContent()}</MainTemplate>;
  }

  return (
    <AuthenticationTemplate isHeaderExtended={false}>
      {pageContent()}
    </AuthenticationTemplate>
  );
};

export default ErrorPage;
